class Navigation extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    if (this.props.currentUser && this.props.confirmedPlan && this.props.currentUser.role == "admin" && this.props.account.eulaAccepted) {
      return <nav className="mdl-navigation">
        <ScanhowNavigationLink text={DkScanHowLocalisation.t("web_header_menubtn_overview")} hashLink="#overview" />
        <ScanhowNavigationLink text={DkScanHowLocalisation.t("web_header_menubtn_users")} hashLink="#users" />
      </nav>
    }
    return null
  }
}

Navigation.defaultProps = {}
Navigation.propTypes = {
  currentUser: React.PropTypes.object,
  account: React.PropTypes.object,
}

class ScanhowNavigationLink extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidUpdate() {
    componentHandler.upgradeDom()
  }

  componentDidMount() {
    componentHandler.upgradeDom()
  }

  render() {
    /*var hash = "#"+this.props.hashLink;*/
    return (
      <a className={"mdl-navigation__link" + (window.location.hash == this.props.hashLink ? " selected" : "")}
         href={this.props.hashLink}>{this.props.text}</a>
    )
  }
}

ScanhowNavigationLink.defaultProps = {}
ScanhowNavigationLink.propTypes = {
  hashLink: React.PropTypes.string.isRequired,
  text: React.PropTypes.string.isRequired,
}

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  render() {
    return (
      <header className="mdl-layout__header">
        <div className="mdl-layout__header-row">
          <div className="scanhowLogo">&nbsp;</div>
          {this.props.children}
          <div className="mdl-layout-spacer">&nbsp;</div>
          <LoginControl authUser={this.props.authUser} currentUser={this.props.currentUser} />
        </div>
      </header>
    )
  }
}

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = { currentLanguage: DkScanHowLocalisation.currentLanguage() }
    this.onLanguageSelect = this.onLanguageSelect.bind(this)

  }

  onLanguageSelect(e) {
    var language = e.target.value
    console.log("Chosen language :" + language)

    sessionStorage.setItem("currentLanguage", language)
    this.setState({ currentLanguage: language })
    location.reload()
  }

  render() {
    var languagePicker = <select name="languagePicker" onChange={this.onLanguageSelect}
                                 value={this.state.currentLanguage}>
      <option value="dk">{DkScanHowLocalisation.t("web_footer_picker_language", "dk")}</option>
      <option value="en">{DkScanHowLocalisation.t("web_footer_picker_language", "en")}</option>
      <option value="se">{DkScanHowLocalisation.t("web_footer_picker_language", "se")}</option>
      <option value="no">{DkScanHowLocalisation.t("web_footer_picker_language", "no")}</option>
    </select>
    return (
      <div className="footer">
        <p
          className="centeredBoth">{DkScanHowLocalisation.t("web_footer_label_optimizedforchrome")}&nbsp;&nbsp;{languagePicker}</p>
      </div>
    )
  }
}

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.props = props

    this.state = {
      errMessage: "",
      finishedVerification: false,
      confirmScreen: false,
      sendOrResend: true,
      processing: false,
      verificationId: "",
      twoFactorNumber: "",
      twoFactorCode: "",
      twoFactorAccepted: this.props.twoFactorAccepted,
      twoFactorVerified: this.props.twoFactorVerified,
    }

    this.content = null
    this.onConfirmPlan = this.onConfirmPlan.bind(this)
    this.onChosenPlan = this.onChosenPlan.bind(this)
    this.displayEula = this.displayEula.bind(this)
    this.acceptEulaClicked = this.acceptEulaClicked.bind(this)
    this.eulaAccepted = this.eulaAccepted.bind(this)
    this.acceptEulaError = this.acceptEulaError.bind(this)

    this.accountUpdated = this.accountUpdated.bind(this)
    this.accountUpdateFailed = this.accountUpdateFailed.bind(this)
    this.updateAccount = this.updateAccount.bind(this)
    this.updateFormState = this.updateFormState.bind(this)
    this.fieldUpdated = this.fieldUpdated.bind(this)

    this.keyDownHandler = this.keyDownHandler.bind(this)
    this.confirmCode = this.confirmCode.bind(this)
    this.sendCode = this.sendCode.bind(this)
    this.resendCode = this.resendCode.bind(this)
    this.hasPhoneRegistered = this.hasPhoneRegistered.bind(this)
    this.cancel = this.cancel.bind(this)
  }

  // componentDidUpdate() {
  //   window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
  //   window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
  //   window.addEventListener('popstate', (event) => {
  //     if (event.state) {
  //       this.setState({
  //         twoFactorAccepted: false,
  //       })
  //       this.updateAccount()
  //     }
  //   })
  // }

  onChosenPlan(plan) {
    this.setState({ chosenPlan: plan.id })
    this.props.onChosenPlan(plan)
  }

  onConfirmPlan(planId) {
    console.log("Main onConfirmPlan " + this.props.confirmedPlan)
    this.props.onConfirmPlan(planId)
  }

  displayEula() {
    this.props.showModalForm(true, <div
      dangerouslySetInnerHTML={{ __html: DkScanHowLocalisation.t("web_createorganisation_eula") }} />)
  }

  eulaAccepted() {
  }

  acceptEulaError() {
    this.setState({ errMessage: DkScanHowLocalisation.t("web_general_errMessage_anerroroccurred") })
  }

  acceptEulaClicked() {
    var eulaAcceptedPath = `${DkScanHowDbRefs.Accounts}/${this.props.account.accountKey}/public/eulaAccepted`
    console.log(`${eulaAcceptedPath}`)
    firebase.database().ref(eulaAcceptedPath).set(true).then(
      this.eulaAccepted,
      this.acceptEulaError,
    )
  }

  validateForm() {
    if (this.state.twoFactorNumber !== "" && this.state.twoFactorNumber.length > 6) {

    }
  }

  updateFormState(key, value) {
    this.setState({ [key]: value }, () => this.validateForm())
  }

  fieldUpdated(e) {
    const name = e.target.name
    const value = e.target.value
    this.updateFormState(name, value)
    console.log(name, value)
  }

  changeState() {
    return
  }

  keyDownHandler(e) {
    //Pressed enter
    if (e.keyCode === 13) {
      if ((this.state.twoFactorNumber.length || this.props.account.twoFactorNumber) > 6 && !this.state.confirmScreen) {
        this.sendCode()
      } else if (this.state.confirmScreen) {
        this.confirmCode()
      }
    }
  }

  onSignInSubmit(e) {
    e.preventDefault()
    console.log("CALLING ON SIGN IN SUBMIT")
    this.setCaptcha()
    let determinePhone = !this.hasPhoneRegistered() ? this.state.twoFactorNumber : this.props.account.twoFactorNumber
    let number = "+" + determinePhone
    const appVerifier = window.recaptchaVerifier
    firebase.auth().signInWithPhoneNumber(number, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult
        console.log("OTP has been sent")
        // ...
      }).catch((error) => {
      // Error; SMS not sent
      // ...
      recaptchaVerifier.reset()
      console.log("SMS not sent")
      console.log("Error -->", error)
    })
  }

  setCaptcha() {
    if (!window.recaptchaVerifier) {
      console.log("CALLING SETCAPTCHA")
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("send-code-button", {
        "size": "invisible",
        "callback": (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          this.onSignInSubmit()
          console.log("Recaptcha varified")
        },
        defaultCountry: "DK",
      })
    }
  }

  async sendCode() {
    this.setState({
      processing: true,
      sendOrResend: false,
    })
    this.setCaptcha()
    let determinePhone = !this.hasPhoneRegistered() ? this.state.twoFactorNumber : this.props.account.twoFactorNumber
    let number = "+" + determinePhone
    const appVerifier = window.recaptchaVerifier
    let provider = new firebase.auth.PhoneAuthProvider()
    console.log("Confirm screen -->", this.state.confirmScreen)
    await provider.verifyPhoneNumber(number, appVerifier).then((verifiationData) => {
      console.log("Data -->", verifiationData)
      this.setState({
        confirmScreen: true,
        verificationId: verifiationData,
        processing: false,
      })
    }).catch(error => {
      console.log("Enter valid phone number -->", error)
      // showSnackbar(DkScanHowLocalisation.t("two_factor_valid_phone"))
      showSnackbar(error.message)
      console.log("Erorr message -->", error.message)
      this.setState({
        processing: false,
      })
    })
  }

  async confirmCode() {
    let credentials
    const id = this.state.verificationId
    const code = this.state.twoFactorCode
    let currentUser = firebase.auth().currentUser

    this.setState({
      processing: true,
    })

    try {
      credentials = firebase.auth.PhoneAuthProvider.credential(id, code)
    } catch (error) {
      console.log("Error in confirmCode() -->", error)
      this.setState({
        processing: false,
      })
    }

    try {
      currentUser = await currentUser.unlink(firebase.auth.PhoneAuthProvider.PROVIDER_ID)
    } catch (error) {
      console.log("Error unlinking verification phone number in confirmCode() -->", error)
      currentUser = firebase.auth().currentUser
      // this.setState({
      //   processing: false,
      // })
    }
    try {
      currentUser.linkWithCredential(credentials).then((value) => {
        this.updateAccount()
      }).catch((error) => {
        console.log("Error in confirmCode() -->", error)
        this.setState({
          errMessage: DkScanHowLocalisation.t("two_factor_wrong_code_or_phone_used"),
          processing: false,
        })
      })
    } catch (error) {
      this.setState({ errMessage: DkScanHowLocalisation.t("two_factor_wrong_code"), processing: false })
    }
  }

  hasPhoneRegistered() {
    if (this.props.account.twoFactorNumber) {
      console.log("If ", this.props.account.twoFactorNumber.length > 6)
    } else {
      console.log("Else ", this.state.twoFactorNumber.length > 6)
    }
    return this.props.account.twoFactorNumber ? this.props.account.twoFactorNumber.length > 6 : this.state.twoFactorNumber.length > 6
  }

  updateAccount() {
    this.setState({
      isTwoFactorVerified: true,
    })
    firebase.database().ref(DkScanHowDbRefs.Accounts + "/" + this.props.account.accountKey + "/" + DkScanHowDbRefs.Public).update({
      twoFactorNumber: this.hasPhoneRegistered() ? this.props.account.twoFactorNumber : this.state.twoFactorNumber,
      twoFactorVerified: this.state.isTwoFactorVerified,
      modifiedTime: firebase.database.ServerValue.TIMESTAMP,
    }).then(
      this.accountUpdated,
      this.accountUpdateFailed,
    )
  }

  accountUpdated() {
    this.setState({
      errMessage: "",
      finishedVerification: true,
      processing: false,
      twoFactorCode: "",
    })
    showSnackbar("Oplysningerne er opdateret")
    DkScanHowHelpers.navigateTo("overview")
  }

  accountUpdateFailed(error) {
    this.setState({ errMessage: DkScanHowLocalisation.t("web_general_errMessage_anerroroccurred") })
    console.log("accountUpdateFailed..:" + error)
    DkScanHowHelpers.logError(error)
  }

  cancel() {
    this.setState({
      finishedVerification: true,
    })
    firebase.database().ref(DkScanHowDbRefs.Accounts + "/" + this.props.account.accountKey + "/" + DkScanHowDbRefs.Public).update({
      twoFactorNumber: "",
      twoFactorVerified: false,
      twoFactorAccepted: false,
      modifiedTime: firebase.database.ServerValue.TIMESTAMP,
    }).then(
      this.accountUpdated,
      this.accountUpdateFailed,
    )
    DkScanHowHelpers.navigateTo("overview")
  }

  twoFactorPhoneInputScreen() {
    return <center>
      <h5>{DkScanHowLocalisation.t("two_factor_enabled_by_admin_message")}</h5>
      <p style={{ color: "lightgray" }}>Husk landekode (f.eks: 45123456789)</p>
      <ScanHowInputTextField fieldName="twoFactorNumber"
                             placeholderText={DkScanHowLocalisation.t("two_factor_phone")}
                             value={this.state.twoFactorNumber}
                             onChange={this.fieldUpdated}
                             keyDownHandler={this.keyDownHandler} />
      {!this.state.sendOrResend ? this.confirmCodeButton() : null}
    </center>
  }

  codeVerificationScreen() {
    return <div
      style={{
        "flex-grow": 1,
        "display": "flex",
        "flex-direction": "column",
        "align-items": "center",
        "width": "100%",
      }}>
      <ScanHowInputTextField fieldName="twoFactorNumber"
                             placeholderText={DkScanHowLocalisation.t("two_factor_phone")}
                             value={this.hasPhoneRegistered() ? this.props.account.twoFactorNumber : this.state.twoFactorNumber}
        // onChange={this.fieldUpdated}
                             keyDownHandler={this.keyDownHandler} />
      <ScanHowInputTextField fieldName="twoFactorCode"
                             placeholderText={DkScanHowLocalisation.t("two_factor_code")}
                             value={this.state.twoFactorCode}
                             onChange={this.fieldUpdated}
                             keyDownHandler={this.onCodeSubmit} />
      <br />
      <p className="accountFormErrorMessage">{this.state.errMessage}</p>
      <br />
      {!this.state.sendOrResend ? this.confirmCodeButton() : null}
      <br />
      {this.resendCodeButton()}
      <br />
      {this.props.account.twoFactorVerified ? "" : this.cancelButton()}
    </div>
  }

  shouldDisableConfirmCodeButton() {
    return (this.state.sendOrResend || this.state.twoFactorCode.length < 5)
  }

  confirmCodeButton() {
    return <div>
      <ScanHowButton
        id={"code-button"}
        text={this.state.processing ? DkScanHowLocalisation.t("two_factor_verifying") : this.hasPhoneRegistered() ? DkScanHowLocalisation.t("two_factor_confirm_code") : this.state.confirmScreen ? DkScanHowLocalisation.t("two_factor_confirm_code") : DkScanHowLocalisation.t("two_factor_send_code")}
        onClick={this.confirmCode}
        isDisabled={this.shouldDisableConfirmCodeButton()} />
    </div>
  }

  async resendCode() {
    this.setCaptcha()
    let determinePhone = !this.hasPhoneRegistered() ? this.state.twoFactorNumber : this.props.account.twoFactorNumber
    let number = "+" + determinePhone
    const appVerifier = window.recaptchaVerifier
    let provider = new firebase.auth.PhoneAuthProvider()
    await provider.verifyPhoneNumber(number, appVerifier).then((verifiationData) => {
      console.log("Data -->", verifiationData)
      this.setState({
        confirmScreen: true,
        verificationId: verifiationData,
        processing: false,
      })
    }).catch((error) => {
      error.code === "auth/captcha-check-failed" ? showSnackbar("Refresh page to solve captch again") : showSnackbar("Error resending code")
      console.log("Error -->", error)
      console.log(number)
      console.log(typeof number)
    })
  }

  resendCodeButton() {
    return <div>
      <ScanHowButton
        id={"resend-code"}
        text={this.state.sendOrResend ? DkScanHowLocalisation.t("two_factor_send_code") : DkScanHowLocalisation.t("two_factor_resend_code")}
        onClick={this.state.sendOrResend ? this.sendCode : this.resendCode}
        isDisabled={this.state.processing} />
    </div>
  }

  cancelButton() {
    return <div>
      <ScanHowButton
        id={"cancel"}
        text={DkScanHowLocalisation.t("cancel")}
        onClick={this.cancel}
        isDisabled={this.state.processing} />
    </div>
  }

  render() {
    if (this.props.currentUser && this.props.confirmedPlan && this.props.currentUser.role == "admin") {
      var eulaAccepted = false
      if (this.props.account && this.props.account.eulaAccepted) {
        eulaAccepted = true
      }
      if (this.props.account && this.props.account.twoFactorAccepted) {
        if (!this.state.finishedVerification) {
          DkScanHowHelpers.navigateTo("twoFactorEnabled")
        }
      }
      if (eulaAccepted) {
        console.log("Props location 123-->", this.props.location)
        switch (this.props.location[0]) {
          case "twoFactorEnabled":
            this.content = this.state.confirmScreen || this.props.account.twoFactorNumber ? this.codeVerificationScreen() : this.twoFactorPhoneInputScreen()
            break
          case "users" :
            if (this.props.location[1] != null && this.props.location[1] == "print") {
              var elem = window.document.getElementById("root2")
              elem.classList.add("printMode")
              this.content = <PrintUsersList organisationUsers={this.props.organisationUsers}
                                             accountGroups={this.props.accountGroups} account={this.props.account} />
            } else {
              this.content = <UserAdministrationPage account={this.props.account} currentUser={this.props.currentUser}
                                                     showModalForm={this.props.showModalForm} />
            }
            break
          case "overview":
            this.content =
              <OrganisationDetails account={this.props.account} authUser={this.props.authUser} />
            break
          default:
            this.content = null
            DkScanHowHelpers.navigateTo("overview")
        }
      } else {
        this.content =
          <div className="centeredBoth newEulaMessage">
            <div>
              {DkScanHowLocalisation.t("web_general_newEulaMessage")}
              <br />
              <a href="#"
                 onClick={this.displayEula}>{DkScanHowLocalisation.t("web_createorganisation_eulalink_eulaandprivacypolicy")}</a>
              <br />
              <br />
              <ScanHowButton onClick={this.acceptEulaClicked}
                             text={DkScanHowLocalisation.t("web_general_btn_iAcceptTheNewEula")} />
              <br />
              <p className="accountFormErrorMessage">{this.state.errMessage}</p>
            </div>
          </div>
      }
    } else if (this.props.currentUser && this.props.currentUser.role == "ungesAdmin") {
      DkScanHowHelpers.navigateTo("")
      this.content = null
    } else {
      DkScanHowHelpers.navigateTo("")
      this.content = <CreateOrganisation onChosenPlan={this.onChosenPlan} chosenPlan={this.props.chosenPlan}
                                         onCreation={this.props.onCreation} confirmedPlan={this.props.confirmedPlan}
                                         onConfirmPlan={this.onConfirmPlan} accountType={this.props.accountType}
                                         account={this.props.account} authUser={this.props.authUser}
                                         currentUser={this.props.currentUser}
                                         showModalForm={this.props.showModalForm} />
    }
    return (
      <main className="mdl-layout__content">
        <div className="innerContainer">
          <div id={"send-code-button"} />
          {this.content}
        </div>
      </main>
    )
  }
}

Main.propTypes = {
  authUser: React.PropTypes.object,
  chosenPlan: React.PropTypes.string,
  confirmedPlan: React.PropTypes.string,
  onChosenPlan: React.PropTypes.func.isRequired,
  onConfirmPlan: React.PropTypes.func.isRequired,
  currentUser: React.PropTypes.object,
  location: React.PropTypes.array.isRequired,
  accountType: React.PropTypes.object,
}


function showSnackbar(message) {
  const snackbarContainer = document.querySelector("#snackbarContainer")
  const data = { message: message }
  //noinspection JSUnresolvedFunction
  snackbarContainer.MaterialSnackbar.showSnackbar(data)
  console.log("Snackbar: " + message)
}

class LoginControl extends React.Component {
  constructor(props) {
    super(props)
    // This binding is necessary to make `this` work in the callback
    this.handleLoginClick = this.handleLoginClick.bind(this)
    this.handleLogoutClick = this.handleLogoutClick.bind(this)
    this.handleExitSupportClick = this.handleExitSupportClick.bind(this)
    this.fieldUpdated = this.fieldUpdated.bind(this)
    this.keyDownHandler = this.keyDownHandler.bind(this)
    this.state = { isLoggingIn: false, loginField: "", passwordField: "" }
    /*console.log("LoginControl : "+props.authUser);*/
    this.props = props
  }

  fieldUpdated(e) {
    const key = e.target.name
    const value = e.target.value
    this.setState({ [key]: value })
  }

  keyDownHandler(e) {
    //Pressed enter
    if (e.keyCode === 13) {
      this.handleLoginClick()
    }
  }

  login() {
    /*const email = document.getElementById('loginField').value;
    const password = document.getElementById('passwordField').value;
    */
    const email = this.state.loginField
    const password = this.state.passwordField
    let currentUser
    /*if (email.length < 1) {
        showSnackbar('Please enter an email address.');
        return;
    }
    if (password.length < 1) {
        showSnackbar('Skriv venligst et password.');
        return;
    }*/
    firebase.auth().signInWithEmailAndPassword(email, password).then((firebaseUser) => {
      // Success
      this.setState({ passwordField: "", loginField: "" })
      /*console.log("logged user in : " + firebaseUser)*/
    }).catch(function(error) {
      DkScanHowHelpers.logError(error)
    })
  }

  handleLoginClick() {
    this.setState({ isLoggingIn: true })
    if (this.props.authUser) {
      console.log("Already logged in")
    } else {
      this.login()
    }

  }

  handleLogoutClick() {
    this.setState({ isLoggingIn: false })
    if (this.props.authUser) {
      firebase.auth().signOut().then(function() {
        /*console.log('Signed Out');*/
        document.location.reload()
        sessionStorage.clear()
      }).catch(function(error) {
        DkScanHowHelpers.logError(error)
      })
    } else {
      console.log("Already logged out")
    }
  }

  handleExitSupportClick() {
    sessionStorage.clear()
    window.close()
    window.location = "/institution"
  }


  componentDidUpdate() {
    componentHandler.upgradeDom()
    if (this.props.authUser && (this.state.loginField != "" || this.state.passwordField != "")) {
      this.setState({ loginField: "", passwordField: "" })
    }
  }

  componentDidMount() {
    componentHandler.upgradeDom()
    if (DKScanhowDebugMode) {
      this.setState({ loginField: "", passwordField: "" })
    }
  }

  render() {

    let loginArea = null
    if (this.props.authUser) {
      if (this.props.currentUser && (this.props.currentUser.uid != this.props.authUser.uid)) {
        loginArea = <div><ScanHowButton onClick={this.handleExitSupportClick}
                                        text={DkScanHowLocalisation.t("web_header_btn_leavesupportview")} /></div>
      } else {
        loginArea = <div>
          <ScanHowButton onClick={this.handleLogoutClick} text={DkScanHowLocalisation.t("web_header_btn_logout")} />
        </div>
      }
    } else {
      loginArea = <div>
        <table>
          <tbody>
          <tr>
            <td><ScanHowInputTextField fieldName="loginField"
                                       placeholderText={DkScanHowLocalisation.t("web_header_placeholder_login")}
                                       value={this.state.loginField} onChange={this.fieldUpdated}
                                       keyDownHandler={this.keyDownHandler} /></td>
            <td><ScanHowInputTextField fieldName="passwordField"
                                       placeholderText={DkScanHowLocalisation.t("web_header_placeholder_password")}
                                       value={this.state.passwordField} onChange={this.fieldUpdated} type="password"
                                       keyDownHandler={this.keyDownHandler} /></td>
            <td><ScanHowButton onClick={this.handleLoginClick} text={DkScanHowLocalisation.t("web_header_btn_login")} />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    }

    return (
      <div className="loginControl">
        {loginArea}
      </div>
    )
  }
}

LoginControl.propTypes = {
  currentUser: React.PropTypes.object,
}

function hasBabelizedJS() {
  console.log("hasBabelizedJS")
}

function SnackbarContainer() {
  return (
    <div id="snackbarContainer" className="mdl-js-snackbar mdl-snackbar">
      <div className="mdl-snackbar__text"></div>
      <button className="mdl-snackbar__action" type="button" />
    </div>
  )
}


class ReactApp extends React.Component {
  constructor(props) {
    super(props)
    // This binding is necessary to make `this` work in the callback
    this.props = props
    this.state = {
      authUser: null,
      currentUser: null,
      location: "",
      account: null,
      confirmedPlan: "",
      authInitialized: false,
      chosenPlan: "",
      accountType: null,
      currentAccount: {},
      modalForm: null,
    }
    this.onAuthStateChanged = this.onAuthStateChanged.bind(this)
    this.onConfirmPlan = this.onConfirmPlan.bind(this)
    this.onChosenPlan = this.onChosenPlan.bind(this)
    this.didNavigate = this.didNavigate.bind(this)
    this.onCreation = this.onCreation.bind(this)
    this.nullifyState = this.nullifyState.bind(this)
    this.initUser = this.initUser.bind(this)
    this.showModalForm = this.showModalForm.bind(this)
    this.closeModalForm = this.closeModalForm.bind(this)

    /*console.log("Initer init :" + this.state.authUser)*/
  }

  nullifyState(authUser) {
    this.setState({
      authInitialized: true,
      currentUser: null,
      account: null,
      chosenPlan: null,
      accountType: null,
      confirmedPlan: null,
      authUser: authUser,
    })
  };

  onAuthStateChanged(authUser) {
    console.log("onAuthStateChanged :" + authUser)
    /*alert("onAuthStateChanged");*/

    /*this.setState({authUser:user}, (nullifyState)=>{*/

    if (authUser) {
      this.initUser(authUser)
    } else {
      this.setState({
        authInitialized: true,
        currentUser: null,
        account: null,
        chosenPlan: null,
        accountType: null,
        confirmedPlan: null,
        authUser: authUser,
      })
    }
    /*});*/
  }

  initUser(authUser) {
    var currentUserId = authUser.uid
    if (typeof (Storage) !== "undefined" && sessionStorage.getItem("supportView")) {
      currentUserId = sessionStorage.getItem("supportView")
      console.log("supportView: " + currentUserId)
    }
    firebase.database().ref("users/" + currentUserId).once("value").then((snapshot) => {
      let currentUser = snapshot && snapshot.val() ? snapshot.val() : null
      if (currentUser) {
        if (currentUser.role.toLowerCase().indexOf("admin") == -1) {
          console.log(snapshot.val())
          showSnackbar(DkScanHowLocalisation.t("web_general_errMessage_usernotfound"))
          console.log("Signing out")
          firebase.auth().signOut()
        } else if (currentUser.accountKey == undefined) {
          this.setState({
            authInitialized: true,
            currentUser: currentUser,
            account: null,
            accountType: null,
            confirmedPlan: null,
            authUser: authUser,
          })
        } else {
          firebase.database().ref(DkScanHowDbRefs.Accounts + "/" + currentUser.accountKey + "/" + DkScanHowDbRefs.Public).on("value", (snapshot) => {
            console.log(snapshot.val())
            if (snapshot && snapshot.val()) {
              let account = snapshot.val()
              firebase.database().ref("accountTypes/" + account.accountType).once("value").then((accountTypeSnapshot) => {
                if (accountTypeSnapshot) {
                  this.setState({
                    authInitialized: true,
                    currentUser: currentUser,
                    account: account,
                    chosenPlan: (account.chosenPlan ? account.chosenPlan : ""),
                    confirmedPlan: (account.confirmedPlan ? account.confirmedPlan : ""),
                    accountType: accountTypeSnapshot.val() ? accountTypeSnapshot.val() : null,
                    authUser: authUser,
                  })
                } else {
                  this.nullifyState(authUser)
                }
              }, function(error) {
                // An error happened.
                DkScanHowHelpers.logError(error)
              })
            } else {
              this.setState({
                authInitialized: true,
                currentUser: currentUser,
                account: null,
                accountType: null,
                confirmedPlan: null,
                authUser: authUser,
              })
            }
          }, function(error) {
            // An error happened.
            console.log("An error happened")
            DkScanHowHelpers.logError(error)
          })
        }
      } else {
        this.setState({
          authInitialized: true,
          currentUser: null,
          account: null,
          accountType: null,
          confirmedPlan: null,
          authUser: authUser,
        })
      }
    }, function(error) {
      // An error happened.
      DkScanHowHelpers.logError(error)
    })
  }

  didNavigate() {
    /*console.log("didNavigate old location:" + this.state.location);*/

    let newLocation = window.location.hash.replace(/^#\/?|\/$/g, "").split("/")

    if (newLocation[1] != null && newLocation[1] == "print") {
      var elem = window.document.getElementById("root2")
      elem.classList.add("printMode")
    } else {
      var elem = window.document.getElementById("root2")
      elem.classList.remove("printMode")
    }

    /*console.log("didNavigate new location : " + newLocation);*/
    this.setState({
      location: newLocation,
    })

  }

  onChosenPlan(plan) {
    this.setState({ chosenPlan: plan.id, accountType: null })

  }

  onConfirmPlan(planId) {
    this.setState({ confirmedPlan: planId })
  }

  onCreation() {
    this.initUser(this.state.authUser)
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => this.onAuthStateChanged(user))
    window.addEventListener("hashchange", this.didNavigate, false)
    this.didNavigate()
  }

  showModalForm(show, modalContent) {
    DkScanHowHelpers.displayModal(show)
    if (show && modalContent) {
      this.setState({
        modalForm: <div className="modal-content">
          {modalContent}
          <br />
          <br />
          <div className="right"><ScanHowButton text={DkScanHowLocalisation.t("web_modalform_btn_close")}
                                                onClick={this.closeModalForm} /></div>
        </div>,
      })
    } else {
      this.setState({ modalForm: null })
    }
  }

  closeModalForm() {
    this.showModalForm(false)
  }


  render() {
    /*console.log("Main render " + JSON.stringify(this.state.chosenPlan))*/
    return (
      <div>
        <div id="dkScanHowModal" className="modal">
          {this.state.modalForm}
        </div>
        <div className="mdl-layout mdl-js-layout mdl-layout--fixed-header">
          <Header authUser={this.state.authUser} currentUser={this.state.currentUser}
                  confirmedPlan={this.state.confirmedPlan}>
            <Navigation currentUser={this.state.currentUser} confirmedPlan={this.state.confirmedPlan}
                        account={this.state.account} />
          </Header>
          {this.state.authInitialized ?
            <Main currentUser={this.state.currentUser} authUser={this.state.authUser} chosenPlan={this.state.chosenPlan}
                  location={this.state.location} onChosenPlan={this.onChosenPlan}
                  confirmedPlan={this.state.confirmedPlan} onConfirmPlan={this.onConfirmPlan}
                  onCreation={this.onCreation} accountType={this.state.accountType} account={this.state.account}
                  showModalForm={this.showModalForm} /> : null}
          <Footer />
          <DebugContainer authUser={this.state.authUser} currentUser={this.state.currentUser}
                          chosenPlan={this.state.chosenPlan} confirmedPlan={this.state.confirmedPlan}
                          accountType={this.state.accountType} account={this.state.account} />
          <SnackbarContainer />
        </div>
      </div>
    )
  }
}

ReactApp.propTypes = {}


class DebugContainer extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = { currentAccount: null, currentUser: {} }

    /*console.log("DebugContainer Constructor");*/
  }

  componentDidMount() {
    if (this.props.authUser) {

      firebase.database().ref(DkScanHowDbRefs.Accounts + "/").orderByChild("admin").equalTo(this.props.authUser.uid).limitToFirst(1).once("value").then((snapshot) => {
        if (snapshot && snapshot.numChildren() == 1) {
          let account = DkScanHowHelpers.objectToArray(snapshot.val())[0]
          if (!this.state.currentAccount) {
            this.setState({
              currentAccount: account,
            })
          }
        }
      }, function(error) {
        // An error happened.
        DkScanHowHelpers.logError(error)
        console.log("Error : " + error.code + " / " + error.message)
      })
    } else {
      if (this.state.currentAccount) {
        this.setState({
          currentAccount: "",
        })
      }
    }
  }

  render() {
    if (!DKScanhowDebugMode) {
      return null
    }
    /*console.log("DebugContainer " + this.props.authUser + " / " + this.state.currentAccount);*/
    return (
      <div className="fullWidth ">
        <div>authUser : {this.props.authUser ? this.props.authUser.email : "-"}</div>
        <div>currentUser: {this.props.currentUser ? JSON.stringify(this.props.currentUser) : "-"}</div>
        <div>userId: {this.props.authUser ? this.props.authUser.uid : "-"}</div>
        <div>chosenPlan : {this.props.chosenPlan ? this.props.chosenPlan : "-"}</div>
        <div>confirmedPlan : {this.props.confirmedPlan ? this.props.confirmedPlan : "-"}</div>
        <div>accountKey : {this.props.account ? this.props.account.accountKey : "-"}</div>
        <div>accountType : {this.props.accountType ? JSON.stringify(this.props.accountType) : "-"}</div>
        <hr />
        <div>currentAccount: {this.state.currentAccount ? JSON.stringify(this.state.currentAccount) : "-"}</div>
        <div>supportView: {typeof (Storage) !== "undefined" && sessionStorage.getItem("supportView") ? sessionStorage.getItem("supportView") : "null"}</div>
        <div>Email Verified
          ({this.props.authUser ? this.props.authUser.email : "-"}): {this.props.authUser ? (this.props.authUser.emailVerified ? "true" : "false") : "-"}</div>
        <div>Account: {this.props.account ? JSON.stringify(this.props.account) : "-"}</div>
      </div>
    )
  }
}

// Specifies the default values for props:
DebugContainer.propTypes = {
  chosenPlan: React.PropTypes.string,
  confirmedPlan: React.PropTypes.string,
}


window.onload = function() {
  hasBabelizedJS()
  /*    console.log(process.env.NODE_ENV);*/
  ReactDOM.render(
    <div>
      <ReactApp />
    </div>,
    document.getElementById("root2"),
  )
}
